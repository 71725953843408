//
// Dropzone
//

.dropzone {
  min-height: 230px;
  border: 2px dashed var(--#{$prefix}border-color);
  background: var(--#{$prefix}secondary-bg);
  border-radius: 6px;
  border-color: #000 !important;

  .dz-message {
    font-size: var(--#{$prefix}font-5xl);
    width: 100%;
    margin: 1em 0;
  }
}

[data-dz-thumbnail] {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  background: var(--#{$prefix}light);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-remove {
  margin-top: 10px;
  font-size: var(--#{$prefix}font-xs);
}