// 
// _card.scss
// 

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $card-box-shadow;
}

.card-header {
    border-bottom: var(--#{$prefix}card-header-border-width) solid $card-border-color;
    padding-bottom: var(--#{$prefix}card-bottom-padding);
}

//Card Header Dropdown
.card-header-dropdown {
    .dropdown-btn {
        padding: $card-cap-padding-y 0;
    }
}

.card-footer {
    border-top: 1px solid $card-border-color;
}

.card-title {
    font-size: var(--#{$prefix}font-lg);
    margin: 0 0 7px 0;
}

.card-height-100 {
    height: calc(100% - #{$grid-gutter-width});
}

.card-animate {
    transition: all 0.4s;

    &:hover {
        transform: translateY(calc(var(--#{$prefix}grid-gutter-width) * -0.5));
        box-shadow: $box-shadow-lg;
    }
}

.card-preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.6);
    z-index: 9999;
}

.card-status {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
}

.custom-loader {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg)
    }
}

.card-overlay {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        background-color: rgba(var(--#{$prefix}primary-rgb), 0.50);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    :is(.card-header, .card-footer) {
        border-color: rgba($white, 0.15) !important;
    }
}

.card-toolbar-menu {
    line-height: 0.8;

    a {
        font-size: 17px;
    }

    .minimize-card {
        .plus {
            display: none;
        }

        .minus {
            display: block;
        }

        &.collapsed {
            .plus {
                display: block;
            }

            .minus {
                display: none;
            }
        }
    }
}

// card color variant
@each $state in map-keys($theme-colors) {

    .card-#{$state} {
        background-color: var(--#{$prefix}#{$state});

        :is(.card-header, .card-footer) {
            background-color: rgba($white, 0.1);
            border-color: transparent;
        }
    }

    .card-border-#{$state} {
        border-color: var(--#{$prefix}#{$state}-border-subtle) !important;
    }
}

@each $color,
$value in $theme-colors {

    .card-#{$color} {
        --#{$prefix}card-color: #{color-contrast($value)};
    }
}