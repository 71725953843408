@import "./bootstrap.scss";
@import "./app.scss";
@import "./icons.scss";

//rtl SCSS
// @import "rtl/structure/structure";
// @import "rtl/components/components";
// @import "rtl/plugins/plugins";
// @import "rtl/apps/apps";
// @import "rtl/pages/pages";

//Emoji picker
aside.EmojiPickerReact.epr-main {
    position: absolute !important;
    bottom: 82px;
    z-index: 2;
    left: 23px;
}

[dir="rtl"] .EmojiPickerReact.epr-main {
    right: 23px !important;
    left: unset !important;
}
.rounded-pill {
    border-radius: var(--tb-border-radius-pill) !important;
    border: 1px solid;
    height: 25px;
    width: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.nav-item{
    z-index: 9;
}
.nav-item .active{
    background-color: green;
    color: #fff;
}

.App-header {
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 5vh;
    text-align: center;
    padding: 12px;
   }

   .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }
  .rdw-editor-wrapper {
    box-sizing: content-box;
    height: 500px;
    width: 96%;
}
.rdw-editor-main {
    height: 100%;
    overflow: auto;
    height: 400px;
    box-sizing: border-box;
}
.carousel-inner {
  overflow: visible;
}

.thumbnail-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;

  /* Optional: Add box shadow or border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.thumbnail-container:hover {
  transform: scale(1.05);
}

.thumbnail-image {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity 0.3s ease;
}

.thumbnail-image:hover {
  opacity: 0.8;
}
.small-font {
  font-size: 12px !important;
}

.form-control:disabled{
  color: #000 !important;
}