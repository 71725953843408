/*
Template Name: Steex - Admin & Dashboard Template
Author: Themesbrand
Website: https://relibuild.com/
Contact: support@themesbrand.com
File: Custom Css File
*/      

.infoButton{
     border-radius: 100%;
    height: 18px;
    width: 18px;
    margin-left: 4px;
    font-size: 13px;
     padding: 0;
     color: #fff;
     border: 0;
     background-color: #e96904 !important;
     
}
.infoButton:hover{
    background-color: #e96904 !important;
    color: #fff !important;
}