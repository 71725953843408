//
// _profile.scss
//

.profile-media{
    .fixed-width {
        height: 185px;
    }
}

.profile-user {
    position: relative;
    display: inline-block;

    .profile-photo-edit {
        position: absolute;
        right: 0;
        left: auto;
        bottom: 0;
        cursor: pointer;
    }

    .user-profile-image {
        object-fit: cover;
    }

    .profile-img-file-input {
        display: none;
    }
}