// styles.scss

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Black background with opacity
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; // Ensure the overlay is on top
  visibility: hidden; // Hide the overlay by default
  opacity: 0; // Hide the overlay by default
  transition: opacity 0.3s ease, visibility 0.3s ease; // Smooth transition
}

.custom-overlay.show {
  visibility: visible; // Show the overlay
  opacity: 1; // Show the overlay
}

.custom-spinner {
  color: white; // Spinner color
}
